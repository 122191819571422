import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSubscription from '@util/hooks/useSubscription';
import { usePlan } from '@util/providers/Plan';
import { useUser } from '@util/providers/AuthProvider';
import ProtectedPage from '@components/ProtectedPage';
import Go from '@util/CustomRedirect';
import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';
import PlanDetails from '@modules/PlanDetails';

export default function MyPlanPage() {
  const { t } = useTranslation();
  const subscription = useSubscription();
  const { bmoUser } = useUser();
  const { setTermIsYearly, setSelectedPlan, setSelectedRate } = usePlan();
  const { details } = subscription;
  const { plan: currentPlan, ratePlan: currentRatePlan } = details;
  const autoRenew = bmoUser?.zuoraInfo?.subscriptions[0]?.autoRenew;
  
    //* set selected plan and rate to user's current plan and rate
  useEffect(() => {
    currentPlan && setSelectedPlan(currentPlan);
    currentRatePlan && setSelectedRate(currentRatePlan);
    // eslint-disable-next-line
  }, [details]);

  useEffect(() => {
    setTermIsYearly(
      currentPlan?.staticRatePlan
        ? true
        : currentRatePlan?.isYearly
        ? true
        : false
    );
  }, [setTermIsYearly, currentPlan, currentRatePlan]);

  return (
    <ProtectedPage>
      <Rules>
        <SEO title={t('page:planDetails')} />
        <div className="row">
          <div className="col-12 col-sm-8">
            <PageHeader>{t('page:planDetails')}</PageHeader>
          </div>
        </div>
        <PlanDetails subscription={subscription} autoRenew={autoRenew} />
      </Rules>
    </ProtectedPage>
  );
}

function Rules({ children }) {
  const subscription = useSubscription();
  const homeRules = !subscription?.isSubscriber;
  return (
    <Go to="/" when={homeRules}>
      {children}
    </Go>
  );
}
