import React, { useEffect } from 'react';
import useAxios from 'axios-hooks';
import URLS from '@config/urls';
import { useTranslation } from 'react-i18next';
import { useUser } from '@util/providers/AuthProvider';
import querystring from 'querystring';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default function AutoRenew({ isOpen, toggle, setAutoRenew, turnOn }) {
  const { t } = useTranslation();

  var onOrOff = turnOn ? 'On' : 'Off';
  const { ssoUser, bmoUser, setBmoUser } = useUser();
  const [{ data, loading, error }, toggleAutoRenew] = useAxios(
    {
      url:
        URLS.AUTO_RENEW + '?currency=' + bmoUser.zuoraInfo.basicInfo.currency,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${ssoUser.access_token}`,
      },
    },
    {
      manual: true,
    }
  );

  if (error) {
    toggle();
  }
  const bulletList = t(`modify.turnAutoRenew${onOrOff}.points`, {
    returnObjects: true,
  });

  useEffect(() => {
    if (data?.success) {
      setBmoUser(prevUser => {
        prevUser.zuoraInfo.subscriptions[0].autoRenew = turnOn;
        prevUser.zuoraInfo.subscriptions[0].id = data.subscriptionId;
        return {
          ...prevUser,
          futureAmendments: {},
        };
      });
      toggle();
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader>
        {t('modify.turnAutoRenew' + onOrOff + '.header')}
        <button
          type="button"
          className="close"
          id="AutoRenewModalClose"
          onClick={toggle}
          aria-label="Close"
        >
          <span aria-hidden="true" />
        </button>
      </ModalHeader>
      <ModalBody className="text-left pt-0 pb-0">
        <p>{t('modify.turnAutoRenew' + onOrOff + '.areYouSure')}</p>

        {bulletList.length > 0 && (
          <ul>
            {bulletList.map((p, i) => (
              <li key={`point_${i}`}>{p}</li>
            ))}
          </ul>
        )}
        <p>{t('modify.turnAutoRenew' + onOrOff + '.footer')}</p>
      </ModalBody>
      <ModalFooter className="pb-5">
        <button
          type="button"
          id="ProceedButton"
          onClick={() => {
            const params = querystring.stringify({
              subscriptionId: bmoUser.zuoraInfo.subscriptions[0].id,
              isAutoRenew: turnOn,
            });

            toggleAutoRenew({
              data: params,
            });
          }}
          className="btn btn-primary"
          disabled={loading}
        >
          {loading && (
            <>
              <span
                className="spinner-corner spinner-corner-sm align-middle mt-n1"
                role="status"
                aria-hidden="true"
              ></span>{' '}
            </>
          )}
          {t('action.yes')}
        </button>
        <button
          className="btn btn-outline-secondary"
          id="AutoRenewModalCancel"
          onClick={() => {
            toggle();
          }}
          disabled={loading}
        >
          {t('action.cancel')}
        </button>
      </ModalFooter>
    </Modal>
  );
}
