import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import moment from 'moment';
import i18n from '../../i18n/i18n';
import 'moment/min/locales.min';
import { useTranslation } from 'react-i18next';
import { PlanCard } from '@ui/PlanCard';
import { useUser } from '@util/providers/AuthProvider';
import { getCountryNameFromCountryCode } from '@util/addressFunctions';
import { SubscriptionStatusEnum } from '@util/Enums/SubscriptionStatusEnum';
import {
  useSubscriptionStatus,
  usePaymentTypeIsPO,
  useFirstThirtyDaysAndLate,
} from '@util/SubscriptionInfo';

export default function BillingPaymentDetailsCard(subscription) {
  const { t } = useTranslation();
  moment.locale(i18n.language.substring(0, 2));
  const { bmoUser } = useUser();
  const subscriptionStatus = useSubscriptionStatus();
  const {
    billingDetails,
    paymentDetails,
    isActiveSubscriber,
  } = subscription.subscription;
  const subscriptionEndDate =
    bmoUser && moment(bmoUser.zuoraInfo.subscriptions[0].termEndDate);
  const now = moment();
  const invoiceEndDate =
    bmoUser && moment(bmoUser.zuoraInfo.invoices[0].dueDate);
  var daysUntilPaymentDue =
    subscriptionEndDate && subscriptionEndDate.diff(now, 'days');
  const daysUntilInvoiceDue =
    invoiceEndDate && invoiceEndDate.diff(now, 'days');
  const paidLatestInvoice =
    bmoUser && bmoUser.zuoraInfo.invoices[0].balance === 0;
  var endDate = subscriptionEndDate;
  const paymentTypeIsPurchaseOrder = usePaymentTypeIsPO(subscription);
  const gracePeriodLength = paymentTypeIsPurchaseOrder ? 45 : 15;
  var gracePeriodDaysLeft = gracePeriodLength + endDate.diff(now, 'days');

  if (useFirstThirtyDaysAndLate() || !paidLatestInvoice) {
    daysUntilPaymentDue = daysUntilInvoiceDue;
    gracePeriodDaysLeft = daysUntilInvoiceDue;
    endDate = invoiceEndDate;
  }

  const PONumber = bmoUser.PONumber;
  const isYearly = subscription?.subscription?.details?.ratePlan?.isYearly
    ? subscription.subscription.details.ratePlan.isYearly
    : false;

  function TaxExemptLink() {
    return <Link to="/taxexempt/start">{t('action.imTaxExempt')}</Link>;
  }

  function BillingLinks() {
    if (paymentTypeIsPurchaseOrder || !isActiveSubscriber) {
      return <TaxExemptLink />;
    } else {
      return (
        <>
          <Link to="/myplan/paymentdetails">{t('action.modify')}</Link>
          {' | '}
          <TaxExemptLink />
        </>
      );
    }
  }

  return (
    <div className="col-12 col-lg-8 mb-4 mb-lg-0 d-flex">
      <PlanCard className="d-inline-block text-left mb-0 p-0" noHover>
        <PlanCard.Body className="p-4">
          <BillingPaymentContainer className="row">
            <div className="col-12 col-sm-6">
              <h5 className="pb-0 pb-sm-3 text-dark">{t('invoice.billing')}</h5>
              <div className="text-muted">
                <div>
                  {billingDetails?.firstName + ' ' + billingDetails?.lastName}
                </div>
                <div>{billingDetails?.address1}</div>
                {billingDetails?.address2 && (
                  <div>{billingDetails?.address2}</div>
                )}
                <div>
                  {`${billingDetails?.city}, ${billingDetails?.stateProvinceRegion} ${billingDetails?.zipCode}`}
                </div>
                <div>
                  {getCountryNameFromCountryCode(billingDetails?.country)}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 mt-4 mt-sm-0">
              <h5 className="pb-0 pb-sm-3 text-dark">{t('invoice.payment')}</h5>
              {
                <div className="text-muted">
                  {paymentTypeIsPurchaseOrder && (
                    <>
                      {t('invoice.purchaseOrderNumber')}: {PONumber && PONumber}
                    </>
                  )}
                  {paymentDetails && paymentDetails.creditCardNumber && (
                    <div className="text-muted">
                      <div>
                        {paymentDetails && paymentDetails.creditCardType}
                      </div>
                      <div>
                        {t('invoice.endingWith')}:{' '}
                        {paymentDetails &&
                          paymentDetails.creditCardNumber &&
                          paymentDetails.creditCardNumber.substring(
                            paymentDetails.creditCardNumber.length - 4
                          )}
                      </div>
                      <div>
                        {t('invoice.expires')}: {paymentDetails.expirationDate}
                      </div>
                    </div>
                  )}
                  {subscriptionStatus === 1 && isYearly && (
                    <p className="text-danger">
                      {t('modify.subscriptionExpiresIn') +
                        ' ' +
                        daysUntilPaymentDue +
                        ' ' +
                        t('modify.days')}
                    </p>
                  )}
                  {subscriptionStatus === 2 && isYearly && (
                    <p className="text-danger">
                      {t('modify.balanceDueIn') +
                        ' ' +
                        gracePeriodDaysLeft +
                        ' ' +
                        t('modify.days')}
                    </p>
                  )}
                  {subscriptionStatus === 3 && isYearly && (
                    <>
                      <p className="text-danger mb-0">
                        {t('modify.paymentPastDue')}
                      </p>
                      <p className="text-danger">
                        {t('modify.yourAccountHasBeenConverted')}
                      </p>
                    </>
                  )}
                  {process.env.ENABLE_DEV_FEATURES === true && (
                    <p className="text-warning">
                      {'DEBUG => PO Status: ' +
                        Object.keys(SubscriptionStatusEnum)[subscriptionStatus]}
                    </p>
                  )}
                </div>
              }
            </div>
          </BillingPaymentContainer>
        </PlanCard.Body>

        <div className="border-top border-white px-4 pt-2 pb-2">
          <BillingLinks />
        </div>
      </PlanCard>
    </div>
  );
}

const BillingPaymentContainer = styled.div`
  line-height: 1.75em;
`;
