import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '@util/providers/AuthProvider';
import moment from 'moment';
import i18n from '@i18n/i18n';
import 'moment/min/locales.min';

export default function RecentOrders() {
  const { bmoUser } = useUser();
  moment.locale(i18n.language.substring(0, 2));
  const { t } = useTranslation();

  return (
    <div>
      {bmoUser.futureAmendments?.records?.length > 0 && (
        <>
          <h3 className="pb-2 mt-4">{t('recentOrders')}</h3>
          <hr />
          {bmoUser.futureAmendments.records.map((amendment, index) => {
            return <AmendmentListing amendment={amendment} key={index} />;
          })}
        </>
      )}
    </div>
  );
}

function AmendmentListing({ amendment }) {
  const { t } = useTranslation();
  const termStartDate = moment(amendment.termStartDate).format('LL');
  const orderDate = moment(amendment.createdDate).format('LL');
  return (
    <>
      <div className="row px-3 mx-1 d-flex justify-content-between">
        <span className="font-weight-bold d-flex">
          <span className="pr-2">{orderDate}</span>
          <span
            className="badge badge-pill badge-primary px-2 py-1 my-auto text-uppercase"
            style={{ fontSize: '0.75rem' }}
          >
            {t('modify.pending')}
          </span>
        </span>
        <span>
          {t('modify.effective')}:{' '}
          <span className="font-weight-bold">{termStartDate}</span>
        </span>
      </div>
      <hr />
      <div className="row mx-1 d-none d-sm-flex">
        <span className="font-weight-bold col-6">{t('modify.plan')}</span>
        <span className="font-weight-bold col-6">{t('modify.term')}</span>
        <span></span>
      </div>
      <hr className="d-none d-sm-flex" />
      <div className="row mx-1 d-none d-sm-flex">
        <span className="col-6">{amendment.amendmentRatePlanName}</span>
        <span className="col-6">
          {amendment.currentTerm} {amendment.currentTermPeriodType}{' '}
          {t('modify.subscription')}
        </span>
        <span></span>
      </div>
      <hr className="d-none d-sm-flex" />
      <div className="px-4 mx-1 d-sm-none">
        <span className="row px-2 font-weight-bold">
          {amendment.amendmentRatePlanName}
        </span>
        <span className="row px-2">
          {amendment.currentTerm} {amendment.currentTermPeriodType}{' '}
          {t('modify.subscription')}
        </span>
        <span></span>
      </div>
      <hr className="d-sm-none" />
    </>
  );
}
