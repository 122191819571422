import { SubscriptionStatusEnum } from '@util/Enums/SubscriptionStatusEnum';
import { useUser } from '@util/providers/AuthProvider';
import moment from 'moment';

export function useSubscriptionStatus() {
  const { bmoUser } = useUser();
  const remittanceWindow = 90;
  const subscriptionEndDate =
    bmoUser && moment(bmoUser.zuoraInfo.subscriptions[0].termEndDate);
  const invoiceEndDate =
    bmoUser && moment(bmoUser.zuoraInfo.invoices[0].dueDate);
  const accountActive =
    bmoUser && bmoUser.zuoraInfo.subscriptions[0].status === 'Active';

  const now = moment();
  var daysUntilPaymentDue =
    subscriptionEndDate && subscriptionEndDate.diff(now, 'days');
  const daysUntilInvoiceDue =
    invoiceEndDate && invoiceEndDate.diff(now, 'days');
  const amountOfInvoices = bmoUser && bmoUser.zuoraInfo.invoices.length;
  const firstThirtyDays =
    amountOfInvoices === 1 &&
    !(
      bmoUser.zuoraInfo.basicInfo.lastPaymentAmount &&
      bmoUser.zuoraInfo.basicInfo.lastPaymentAmount > 0
    );
  const paidLatestInvoice =
    bmoUser && bmoUser.zuoraInfo.invoices[0].balance === 0;
  const gracePeriod =
    daysUntilPaymentDue < 0 && daysUntilPaymentDue >= -30 && accountActive;

  if (accountActive) {
    if ((firstThirtyDays || !paidLatestInvoice) && bmoUser.PONumber) {
      if (daysUntilInvoiceDue < remittanceWindow && !paidLatestInvoice) {
        return SubscriptionStatusEnum.Late;
      } else {
        return SubscriptionStatusEnum.Received;
      }
    } else {
      if (daysUntilPaymentDue > remittanceWindow) {
        return SubscriptionStatusEnum.Received;
      } else if (gracePeriod) {
        return SubscriptionStatusEnum.Late;
      } else if (
        daysUntilPaymentDue <= remittanceWindow &&
        daysUntilPaymentDue >= 0
      ) {
        return SubscriptionStatusEnum.Due;
      } else {
        return SubscriptionStatusEnum.PastDue;
      }
    }
  } else {
    return SubscriptionStatusEnum.PastDue;
  }
}

export function usePaymentTypeIsPO(subscription) {
  const { bmoUser } = useUser();
  const { paymentDetails } = subscription;
  const PONumber = bmoUser?.PONumber;
  return (!paymentDetails || !paymentDetails.creditCardNumber) && PONumber;
}

export function useFirstThirtyDaysAndLate() {
  const { bmoUser } = useUser();
  const now = moment();
  const accountActive =
    bmoUser && bmoUser.zuoraInfo.subscriptions[0].status === 'Active';
  const amountOfInvoices = bmoUser && bmoUser.zuoraInfo.invoices.length;
  const invoiceEndDate =
    bmoUser && moment(bmoUser.zuoraInfo.invoices[0].dueDate);
  const firstThirtyDays =
    amountOfInvoices === 1 &&
    !(
      bmoUser.zuoraInfo.basicInfo.lastPaymentAmount &&
      bmoUser.zuoraInfo.basicInfo.lastPaymentAmount > 0
    );
  const daysUntilInvoiceDue =
    invoiceEndDate && invoiceEndDate.diff(now, 'days');
  const remittanceWindow = 90;
  const paidLatestInvoice =
    bmoUser && bmoUser.zuoraInfo.invoices[0].balance === 0;

  if (
    accountActive &&
    firstThirtyDays &&
    (daysUntilInvoiceDue < remittanceWindow && !paidLatestInvoice)
  ) {
    return true;
  } else {
    return false;
  }
}
