import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'gatsby';
import { useTranslation, Trans } from 'react-i18next';
import { PlanCard } from '@ui/PlanCard';
import PODisclaimer from '@components/Modify/Disclaimers';
import { useUser } from '@util/providers/AuthProvider';
import RateInfo from '@components/PlanCard/RateInfo';
import ArrowLink from '@components/Miscellaneous/ArrowLink';
import RecentOrders from '@components/Modify/RecentOrders';
import i18n from '@i18n/i18n';
import 'moment/min/locales.min';
import BillingPaymentDetailsCard from '@components/Modify/BillingPaymentDetailsCard';
import {
  useSubscriptionStatus,
  usePaymentTypeIsPO,
} from '@util/SubscriptionInfo';
import AutoRenewModal from '@util/Modals/autoRenewModal';

export default function PlanDetails({ subscription, autoRenew }) {
  const { t } = useTranslation();
  const { bmoUser } = useUser();
  moment.locale(i18n.language.substring(0, 2));
  const [modal, setModal] = useState(false);
  const subscriptionStatus = useSubscriptionStatus();
  const paymentTypeIsPurchaseOrder = usePaymentTypeIsPO(subscription);

  const {
    details: { plan, ratePlan },
    isActiveSubscriber,
  } = subscription;

  // eslint-disable-next-line
  const gracePeriodAutoRenewOn = autoRenew && subscriptionStatus == 2;

  function toggleModal() {
    setModal(!modal);
  }

  function SubscriptionCard() {
    return (
      <div className="col-12 col-lg-4 mb-4 mb-lg-0 d-flex">
        <PlanCard className="text-left mb-0 p-0 border-0" noHover>
          <PlanCard.Body className="p-4">
            <h5 className="pb-3 text-dark">
              {plan && plan?.name?.toUpperCase()}
            </h5>
            <RateInfo rate={ratePlan} plan={plan} myPlan />
            {plan && plan.key && (
              <div className="learn-more-link">
                <ArrowLink
                  id={'LearnMore' + plan.key}
                  data-testid={'LearnMore' + plan.key}
                  to={`/${plan.key}`}
                >
                  {t('action.learnMore')}
                </ArrowLink>
              </div>
            )}
          </PlanCard.Body>
          <div className="border-top border-white px-4 d-flex justify-content-between align-items-center">
            <div className="my-2">
              <ModifyLink expiredSubscriber={!isActiveSubscriber} />
            </div>
            {!paymentTypeIsPurchaseOrder && isActiveSubscriber && (
              <div className="text-sm-right">
                {t('modify.autoRenewal')}:&nbsp;
                <button
                  className="btn btn-link px-0 mb-1"
                  onClick={toggleModal}
                >
                  {autoRenew === true ? t('modify.on') : t('modify.off')}
                </button>
              </div>
            )}
          </div>
        </PlanCard>
        <AutoRenewModal
          isOpen={modal}
          toggle={toggleModal}
          turnOn={!autoRenew}
        />
      </div>
    );
  }

  function ModifyLink({ expiredSubscriber }) {
    const { t } = useTranslation();
    if (paymentTypeIsPurchaseOrder) return <span>&nbsp;</span>;
    if (expiredSubscriber) {
      return <Link to="/myplan/modify">{t('action.resubscribe')}</Link>;
    }
    if (bmoUser?.futureAmendments?.records?.length > 0)
      return (
        <span className="text-muted">{t('modify.modificationPending')}</span>
      );
    return <Link to="/myplan/modify">{t('action.modify')}</Link>;
  }

  function PaymentDeclined() {
    const paidLatestInvoice =
      bmoUser && bmoUser.zuoraInfo.invoices[0].balance === 0;
    const gracePeriodExtension = paymentTypeIsPurchaseOrder ? 45 : 15;
    const gracePeriodEndDate = !paidLatestInvoice
      ? moment(bmoUser.zuoraInfo.invoices[0].dueDate)
          .add(gracePeriodExtension, 'd')
          .format('LL')
      : moment(bmoUser.zuoraInfo.subscriptions[0].termEndDate)
          .add(gracePeriodExtension, 'd')
          .format('LL');

    return (
      <div className="row">
        <div className="col-12">
          <PlanCard className="text-left pl-4 py-2 mb-3 d-inline-block" noHover>
            {/* prettier-ignore */}
            <Trans i18nKey="modify.paymentDeclined">
              Your subscription renewal transaction was declined. Please modify
              your payment method by
              <strong>
                {{
                  date: moment(gracePeriodEndDate)
                    .subtract(1, 'd')
                    .format('LL'),
                }}
              </strong>
              or your subscription will be canceled.
            </Trans>
          </PlanCard>
        </div>
      </div>
    );
  }

  function CustomerSupportBlock() {
    return (
      <div className="row">
        <div className="col-12">
          <PlanCard className="text-left pl-4 py-2 mb-3 d-inline-block" noHover>
            {/* prettier-ignore */}
            <Trans i18nKey="modify.contactCustomerSupport">
              Contact     
              <a
                href={process.env.CONTACT_US}
                rel="noopener noreferrer"
                target="_blank"
              >
                {{ supportLink: t('customerSupport') }}
              </a>
              to modify your subscription
            </Trans>
            .
          </PlanCard>
        </div>
      </div>
    );
  }

  function CCDisclaimer() {
    const paidLatestInvoice =
      bmoUser && bmoUser.zuoraInfo.invoices[0].balance === 0;
    const gracePeriodExtension = paymentTypeIsPurchaseOrder ? 45 : 15;
    const gracePeriodEndDate = !paidLatestInvoice
      ? moment(bmoUser.zuoraInfo.invoices[0].dueDate)
          .add(gracePeriodExtension, 'd')
          .format('LL')
      : moment(bmoUser.zuoraInfo.subscriptions[0].termEndDate)
          .add(gracePeriodExtension, 'd')
          .format('LL');

    const endDate =
      bmoUser &&
      moment(bmoUser.zuoraInfo.subscriptions[0].termEndDate).format('LL');

    const startEndDate =
      bmoUser &&
      moment(bmoUser.subscriptionInfo.contractStartDate)
        .add(1, ratePlan?.isYearly ? 'Y' : 'M')
        .format('LL');

    if (autoRenew && bmoUser.zuoraInfo.subscriptions[0].status === 'Active') {
      if (bmoUser.futureAmendments?.records?.length > 0) {
        return (
          <PlanCard className="text-left px-4 py-2 mb-3 d-inline-block" noHover>
            {/* prettier-ignore */}
            <Trans i18nKey="modify.futureAmendmentDisclaimer">
                Your current {{ planName: plan?.name }} plan will expire on
                <strong>{{ startEndDate }}</strong>. Your new
                {{
                  selectedPlanName:
                    bmoUser.futureAmendments?.records[0].amendmentRatePlanName,
                }}
                plan will start on
                <strong>{{ startEndDate }}</strong> and auto-renew
                {{
                  term:
                    parseInt(
                      bmoUser.futureAmendments?.records[0].currentTerm
                    ) === 12
                      ? 'annually'
                      : 'monthly',
                }}
                .
              </Trans>
          </PlanCard>
        );
      } else {
        return (
          <PlanCard className="text-left px-4 py-2 mb-3 d-inline-block" noHover>
            {/* prettier-ignore */}
            <Trans i18nKey={'modify.recurringPaymentDisclaimerNoPrice'}>
                  Your plan includes a <strong>recurring payment</strong>
                  &nbsp;that will renew
                  <strong>
                    {{
                      date: endDate,
                    }}
                  </strong>
                  .
                </Trans>
          </PlanCard>
        );
      }
    } else {
      if (subscriptionStatus === 2) {
        return (
          <PlanCard className="text-left px-4 py-2 mb-3 d-inline-block" noHover>
            {/* prettier-ignore */}
            <Trans i18nKey="modify.continueSubscription">
              Your subscription will end on
              <strong>{{ date1: gracePeriodEndDate }}</strong>. If you would
              like to continue your subscription for another
              {{
                term: ratePlan?.isYearly
                  ? t('forms.year').toLowerCase()
                  : t('forms.month').toLowerCase(),
              }}
              , turn on Auto-Renewal by
              <strong>
                {{
                  date2: moment(gracePeriodEndDate)
                    .subtract(1, 'd')
                    .format('LL'),
                }}
              </strong>
              and we will process your payment the following day.
            </Trans>
            <br />
            {/* prettier-ignore */}
            {/* <Trans i18nKey="modify.continueSubscription2">
            In order to continue your subscription, turn auto-renew <strong>on</strong> and payment will be made on the following day.
          </Trans> */}
          </PlanCard>
        );
      } else {
        return null;
      }
    }
  }

  function Disclaimer() {
    return paymentTypeIsPurchaseOrder ? <PODisclaimer /> : <CCDisclaimer />;
  }
  return (
    <>
      <div className="row mt-5 mb-4">
        <SubscriptionCard />
        <BillingPaymentDetailsCard subscription={subscription} />
      </div>
      {gracePeriodAutoRenewOn && <PaymentDeclined />}
      {paymentTypeIsPurchaseOrder && <CustomerSupportBlock />}
      {isActiveSubscriber && !gracePeriodAutoRenewOn && <Disclaimer />}
      {autoRenew && <RecentOrders />}
    </>
  );
}
