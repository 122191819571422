import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileUpload from '@util/Forms/FileUpload';
import styled from 'styled-components';
import axios from 'axios';
import URLS from '@config/urls';
import { useUser } from '@util/providers/AuthProvider';
import { useLocale } from '@util/providers/Locale';
import { PlanCard } from '@ui/PlanCard';
import { user, POUploadSchema } from '@data/modify';
import { Formik, Form, Field } from 'formik';
import { getSubscriptionCurrency } from '@util/functions';
import CustomFormErrorMessage from '@ui/CustomFormErrorMessage';
import Checkmark from '@images/checkmarkInverted.svg';
import { useSubscriptionStatus } from '@util/SubscriptionInfo';

export default function Disclaimer() {
  const { t } = useTranslation();
  const { ssoUser } = useUser();
  const { selectedCurrency } = useLocale();
  const subscriptionCurrency = getSubscriptionCurrency(selectedCurrency);

  const subscriptionStatus = useSubscriptionStatus();

  function DueDisclaimer() {
    return (
      <>
        {t('modify.dueDisclaimer')}
        <UploadBlock />
      </>
    );
  }

  function LateDisclaimer() {
    return (
      <>
        {t('modify.lateDisclaimer')}
        <UploadBlock />
      </>
    );
  }
  function PastDueDisclaimer() {
    return (
      <>
        {t('modify.pastDueDisclaimer')}
        <UploadBlock />
      </>
    );
  }

  function UploadBlock() {
    const { t } = useTranslation();
    const formikRef = useRef();
    // eslint-disable-next-line
    const [formIsSubmitting, setFormIsSubmitting] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    return (
      <>
        <br />
        <br />
        <strong>{t('invoice.pleaseSendACopyOfYourInvoice')}</strong>
        <br />
        <br />
        {subscriptionCurrency === 'CAD' ? (
          <>
            <p className="mb-0">Tobii Dynavox Canada Inc</p>
            <p className="mb-0">PO Box 56061</p>
            <p className="mb-0">Postal Station A</p>
            <p>Toronto, ON M5W 4L1</p>
          </>
        ) : (
          <>
            <p className="mb-0">Tobii Dynavox LLC</p>
            <p className="mb-0">PO Box 72153</p>
            <p>Cleveland, OH 44192</p>
          </>
        )}
        <strong>{t('invoice.ifYourPurchaseOrdersReady')}</strong>
        <br />
        <br />
        <Formik
          enableReinitialize
          initialValues={user}
          validationSchema={POUploadSchema}
          ref={formikRef}
          onSubmit={(values, actions) => {
            setFormIsSubmitting(true);
            var formData = new FormData();
            formData.append('uploadFile', values.uploadFile);
            axios({
              method: 'post',
              url: URLS.UPLOAD_PO + '?purchaseOrderNumber=' + values.PONumber,
              data: formData,
              headers: {
                Authorization: `Bearer ${ssoUser.access_token}`,
              },
            })
              .then(res => {
                //* if trial was created successfully
                setFormIsSubmitting(false);
                if (res.status === 200) {
                  setUploadComplete(true);
                }
              })
              .catch(err => {
                // TODO: handle error in the UI
                console.error(err.message);
                //* activate submit button to allow for resubmission
                setFormIsSubmitting(false);
              });
          }}
          render={({ isValid, isSubmitting, values, setFieldValue }) => {
            return (
              <Form className="row">
                {uploadComplete === false ? (
                  <>
                    <div className="col-12 col-sm-9 col-md-7 col-lg-4 pb-2">
                      <label htmlFor={'PONumber'}>{t(`forms.PONumber`)}</label>
                      <Field
                        name={'PONumber'}
                        id={'PONumberField'}
                        className="form-control"
                      />
                      <CustomFormErrorMessage name={'PONumber'} />
                    </div>
                    <div className="col-12 col-sm-9 col-md-7 col-lg-4 p-0">
                      <FileUpload size="col-12" setFile={setFieldValue} />
                    </div>
                    <div className="col-12 col-sm-9 col-md-7 col-lg-4">
                      <p className="mb-2">&nbsp;</p>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isValid || isSubmitting}
                        id="ModifySubmit"
                      >
                        {isSubmitting && (
                          <>
                            <span
                              className="spinner-corner spinner-corner-sm align-middle mt-n1"
                              role="status"
                              aria-hidden="true"
                            />{' '}
                          </>
                        )}
                        {isSubmitting
                          ? t('status.submittingPayment')
                          : t('action.submit')}
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="col-12 px-3">
                    <div className="d-inline-block">
                      <p className="float-left">
                        {t('invoice.uploadSuccessful')}
                      </p>
                      <SmallCheckmark src={Checkmark} alt="yes" />
                    </div>
                    <div className="bg-white text-muted py-1 px-3 align-text-bottom">
                      <span>{values.uploadFile.name}&nbsp; </span>
                    </div>
                    <br />
                    <p>{t('invoice.yourAccountWillContinue')}</p>
                  </div>
                )}
              </Form>
            );
          }}
        />
      </>
    );
  }

  return (
    <div className="row pt-2">
      {subscriptionStatus !== 0 && (
        <div className="col-12">
          <PlanCard className="text-left px-4 py-3 mb-3 d-inline-block" noHover>
            {subscriptionStatus === 1 && <DueDisclaimer />}
            {subscriptionStatus === 2 && <LateDisclaimer />}
            {subscriptionStatus === 3 && <PastDueDisclaimer />}
          </PlanCard>
        </div>
      )}
    </div>
  );
}

const SmallCheckmark = styled.img`
  min-height: 1.25rem;
  min-width: 1.5rem;
  max-height: 1.25rem;
  max-width: 1.5rem;
  float: right;
  padding-left: 3%;
  padding-top: 1%;
`;
