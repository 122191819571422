import * as Yup from 'yup';
//* use i18n key as value for the error messaging
Yup.setLocale({
  mixed: {
    required: 'validation.required',
  },
});

export const POUploadSchema = Yup.object().shape({
  uploadFile: Yup.mixed().required(),
  PONumber: Yup.string().required(),
});

export const user = {
  uploadFile: '',
  PONumber: '',
};
